@font-face {
  font-family: 'ProximaNovaBold';
  font-display: 'swap';
  src: url('./ProximaNova-Bold.eot');
  src: url('./ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('./ProximaNova-Bold.woff2') format('woff2'), url('./ProximaNova-Bold.woff') format('woff'),
    url('./ProximaNova-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-display: 'swap';
  src: url('./ProximaNova-Regular.eot');
  src: url('./ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('./ProximaNova-Regular.woff2') format('woff2'),
    url('./ProximaNova-Regular.woff') format('woff'),
    url('./ProximaNova-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNovaLight';
  font-display: 'swap';
  src: url('./ProximaNova-Light.eot');
  src: url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
    url('./ProximaNova-Light.woff2') format('woff2'), url('./ProximaNova-Light.woff') format('woff'),
    url('./ProximaNova-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNovaRegularIt';
  font-display: 'swap';
  src: url('./ProximaNova-RegularIt.eot');
  src: url('ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
    url('./ProximaNova-RegularIt.woff2') format('woff2'),
    url('./ProximaNova-RegularIt.woff') format('woff'),
    url('./ProximaNova-RegularIt.ttf') format('truetype');
}
