@font-face {
  font-family: 'ProximaNovaBold';
  font-display: 'swap';
  src: url(/static/media/ProximaNova-Bold.eot);
  src: url(/static/media/ProximaNova-Bold.eot?#iefix) format('embedded-opentype'),
    url(/static/media/ProximaNova-Bold.woff2) format('woff2'), url(/static/media/ProximaNova-Bold.woff) format('woff'),
    url(/static/media/ProximaNova-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-display: 'swap';
  src: url(/static/media/ProximaNova-Regular.eot);
  src: url(/static/media/ProximaNova-Regular.eot?#iefix) format('embedded-opentype'),
    url(/static/media/ProximaNova-Regular.woff2) format('woff2'),
    url(/static/media/ProximaNova-Regular.woff) format('woff'),
    url(/static/media/ProximaNova-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNovaLight';
  font-display: 'swap';
  src: url(/static/media/ProximaNova-Light.eot);
  src: url(/static/media/ProximaNova-Light.eot?#iefix) format('embedded-opentype'),
    url(/static/media/ProximaNova-Light.woff2) format('woff2'), url(/static/media/ProximaNova-Light.woff) format('woff'),
    url(/static/media/ProximaNova-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'ProximaNovaRegularIt';
  font-display: 'swap';
  src: url(/static/media/ProximaNova-RegularIt.eot);
  src: url(/static/media/ProximaNova-RegularIt.eot?#iefix) format('embedded-opentype'),
    url(/static/media/ProximaNova-RegularIt.woff2) format('woff2'),
    url(/static/media/ProximaNova-RegularIt.woff) format('woff'),
    url(/static/media/ProximaNova-RegularIt.ttf) format('truetype');
}

